import React from 'react'
import { graphql } from 'gatsby'

import SEO from 'components/SEO/index'
import MediaContent from 'components/Media'

const Media = ({ data, pageContext, location }) => {
  const {
    allMarkdownRemark: { edges }
  } = data
  return (
    <>
      <SEO title="In the Media and Events" />
      <MediaContent data={edges} location={location.pathname} />
    </>
  )
}

export default Media

export const query = graphql`
  query($media: Boolean) {
    allMarkdownRemark(
      filter: { frontmatter: { media: { eq: $media } } }
      sort: { order: DESC, fields: frontmatter___id }
    ) {
      edges {
        node {
          id
          frontmatter {
            id
            title
            images {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
