import React from 'react'
import { Link } from 'gatsby'
import { usePageIconsImages } from 'hooks/usePageIconsImagesHook'
import { formatDate } from 'utils'

import Intro from 'elements/Intro'
import { GatsbyImage } from 'gatsby-plugin-image'

const MediaContent = ({ data, location }) => {
  const {
    mediaIcon: {
      childImageSharp: {
        gatsbyImageData: {
          images: {
            fallback: { src }
          }
        }
      }
    }
  } = usePageIconsImages()

  return (
    <section className="py-16">
      <div className="container mx-auto px-8">
        <Intro pageTitle="In the Media and Events" imgSrc={src} />
        <div className="mt-32">
          {data.length &&
            data.map(
              ({
                node: {
                  id,
                  fields: { slug },
                  frontmatter: { title, images }
                }
              }) => {
                return (
                  <Link
                    key={`${id}-${slug}`}
                    to={`${location}/${slug}`}
                    className="flex bg-white  my-10 text-sm shadow-news-card w-full rounded-lg select-none transition-all duration-300 hover:shadow-news-card-strong cursor-pointer border-transparent border-2 hover:border-vividBlue z-1 relative"
                  >
                    <div className="flex items-center justify-between text-sm bg-white rounded-lg">
                      <div className="w-20 h-20 flex flex-shrink-0 rounded-tl-lg rounded-bl-lg border-0">
                        <GatsbyImage
                          className="object-cover w-full h-full rounded-tl-lg rounded-bl-lg"
                          image={images[0].childImageSharp.gatsbyImageData}
                          alt={title}
                        />
                      </div>
                      <div className="py-2 px-5">
                        <span className="text-sm text-vividBlue font-bold">{title}</span>
                      </div>
                    </div>
                  </Link>
                )
              }
            )}
        </div>
      </div>
    </section>
  )
}

export default MediaContent
